import '@common/application-insights';

import { FC, useEffect, useState } from 'react';

import { ApplicationProvider, I18n } from '@common/application';
import { Label } from '@dc/client/types';
import DigitalCoreProvider from '@dc/provider';
import { Head, errorPaths } from '@dxp-next';
import { Link } from '@dxp-next';
import { LinkComponentProvider } from '@link';
import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { client } from '@sitecore/client/browser';
import { SitecoreContext } from '@sitecore/common';
import type { LayoutServiceData } from '@sitecore/common';
import type { ComponentsDefinition } from '@sitecore/common';
import { SparkyProvider } from '@sparky/providers';
import { TrackingProvider } from '@tracking';

type Props = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  children?: React.ReactNode;
  components: ComponentsDefinition;
  i18n: I18n;
  label: Label;
  layoutData: LayoutServiceData | null;
  name: string;
};

const ErrorPageContainer: FC<Props> = ({ businessUnit, children, components, i18n, label, layoutData, name }) => {
  const [errorLayoutData, setErrorLayoutData] = useState<LayoutServiceData | null>(layoutData);

  useEffect(() => {
    client({ path: errorPaths.error, locale: i18n.locale }).then(({ layoutData }) => {
      setErrorLayoutData(layoutData);
    });
  }, [i18n.locale]);

  if (!errorLayoutData || !errorLayoutData.sitecore.route) return null;

  return (
    <>
      <Head>
        <title>{label}</title>
      </Head>
      <ApplicationProvider i18n={i18n}>
        <TrackingProvider scope={name}>
          <SparkyProvider locale={i18n.locale}>
            <SitecoreContext components={components} layoutData={errorLayoutData}>
              <DigitalCoreProvider
                customerContext={errorLayoutData.sitecore.context}
                label={label}
                businessUnit={businessUnit}>
                <LinkComponentProvider component={Link}>{children}</LinkComponentProvider>
              </DigitalCoreProvider>
            </SitecoreContext>
          </SparkyProvider>
        </TrackingProvider>
      </ApplicationProvider>
    </>
  );
};

export { ErrorPageContainer };
