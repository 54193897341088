import { FC } from 'react';

import { useLayoutData } from '@sitecore/common';
import { PLACEHOLDER_APPLICATION_REGEX } from '@sitecore/common/core/AppPlaceholder';

import AppLayout from './AppLayout';
import DefaultLayout from './DefaultLayout';

const Layout: FC = () => {
  const { route } = useLayoutData();
  const hasAppPlaceholder = Object.keys(route.placeholders).find(name => PLACEHOLDER_APPLICATION_REGEX.test(name));

  return hasAppPlaceholder ? <AppLayout /> : <DefaultLayout />;
};

export default Layout;
