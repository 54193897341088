import React from 'react';

import { Locale as LocaleCode } from '@common/application';
import { Locale } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';
import {
  DayPickerBase,
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
  DaySelectionMode,
} from 'react-day-picker';

import { Caption } from './components/Caption';
import { Day } from './components/Day';
import { HeadRow } from './components/HeadRow';
import { DayPicker } from './Datepicker.styles';
import { CloseIcon } from '../../icons';
import { useI18nTranslations } from '../../providers/i18n';
import { useLocale } from '../../providers/locale';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { Card } from '../Card/Card';
import { Divider } from '../Divider/Divider';
import { IconButton } from '../IconButton/IconButton';
import { Stack } from '../Stack/Stack';

export const dateLocale: Record<LocaleCode, Locale> = {
  'nl-NL': nl,
  'nl-BE': nl,
  'fr-BE': fr,
  'en-NL': enUS,
  'en-GB': enUS,
};

export const Datepicker: React.FC<Props> = ({
  onClose,
  onSave,
  onReset,
  mode = 'single',
  resetButtonLabel,
  saveButtonLabel,
  fromDate,
  toDate,
  toYear,
  numberOfMonths = 1,
  disabled = [],
  disableWeekends,
  ...props
}) => {
  const { save: saveA11yLabel, reset: resetA11yLabel } = useI18nTranslations();
  const locale = useLocale();

  const disabledDates = Array.isArray(disabled) ? disabled : [disabled];

  if (disableWeekends) {
    disabledDates.push({ dayOfWeek: [0, 6] });
  }

  return (
    <Card>
      {onClose ? (
        <>
          <Stack alignX="end">
            <IconButton label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
        </>
      ) : null}
      <DayPicker
        mode={mode}
        locale={dateLocale[locale]}
        fromDate={fromDate}
        toDate={toDate}
        toYear={toYear}
        disabled={disabledDates}
        fixedWeeks
        components={{
          Caption,
          HeadRow,
          Day,
        }}
        numberOfMonths={numberOfMonths}
        pagedNavigation={!!(numberOfMonths && numberOfMonths > 1)}
        {...props}
      />
      {onSave || onReset ? (
        <>
          <Divider />
          <Box padding="6">
            <Stack alignX="start" gap="5" direction="row">
              {onSave ? (
                <Button size="compact" onClick={onSave}>
                  {saveButtonLabel ?? saveA11yLabel}
                </Button>
              ) : null}
              {onReset ? (
                <Button size="compact" action="secondary" type="reset" onClick={onReset}>
                  {resetButtonLabel ?? resetA11yLabel}
                </Button>
              ) : null}
            </Stack>
          </Box>
        </>
      ) : null}
    </Card>
  );
};

export interface DayPickerProps<T extends DaySelectionMode = Exclude<DaySelectionMode, 'default'>>
  extends DayPickerBase {
  mode?: T;
  selected?: T extends 'single'
    ? DayPickerSingleProps['selected']
    : T extends 'multiple'
      ? DayPickerMultipleProps['selected']
      : T extends 'range'
        ? DayPickerRangeProps['selected']
        : DayPickerDefaultProps['selected'];
  onSelect?: T extends 'single'
    ? DayPickerSingleProps['onSelect']
    : T extends 'multiple'
      ? DayPickerMultipleProps['onSelect']
      : T extends 'range'
        ? DayPickerRangeProps['onSelect']
        : never;
}
interface Props extends Omit<DayPickerProps, 'locale'> {
  disableWeekends?: boolean;
  resetButtonLabel?: string;
  saveButtonLabel?: string;
  onSave?(): void;
  onReset?(): void;
  /**
   * Displays a close button, fired when button is clicked
   */
  onClose?(): void;
}
