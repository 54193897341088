import logger from '@common/log';
import { sanitizePath } from '@common/path';
import startMockWorker from '@mocks/worker';
import type { LayoutServiceData, PlaceholderResponse, PlaceholderSearchParams } from '@sitecore/common';

import { host, host_csr, jss, jssPlaceholder, key, site } from './config';
import { BrowserClient, ClientResponse } from './types';

startMockWorker();

const headers: HeadersInit = Object.freeze({
  'content-type': 'application/json',
});

const fetchPlaceholder = async (
  { path, locale, placeholderName, scope }: PlaceholderSearchParams,
  idToken?: string | null,
): Promise<PlaceholderResponse> => {
  const item = sanitizePath(path);
  const url = new URL(jssPlaceholder, host ?? host_csr);
  const urlSearchParams = new URLSearchParams({
    item,
    sc_lang: locale,
    sc_apikey: key,
    sc_site: site,
    placeholderName,
  });
  if (scope) {
    urlSearchParams.set('scope', scope);
  }
  url.search = urlSearchParams.toString();

  const currentHeaders = {
    ...headers,
  };
  if (idToken) {
    currentHeaders['Authorization'] = `Bearer ${idToken}`;
  }

  const response = await fetch(url.href, {
    method: 'GET',
    headers: {
      ...currentHeaders,
    },
    mode: 'same-origin',
    credentials: 'same-origin',
  });
  const statusCode = response.status ?? 500;
  if (response.ok || statusCode === 404) {
    try {
      return { layoutData: await response.json(), statusCode: response.status };
    } catch (e) {
      logger.error('9m782X', 'Error parsing Sitecore response', { error: e, req: { path, item, href: url.href } });
    }
  }
  return { statusCode: response.status };
};

const client = async ({ path, locale }: BrowserClient): Promise<ClientResponse> => {
  const item = sanitizePath(path);
  const url = new URL(jss, host);
  url.search = new URLSearchParams({ item, sc_lang: locale, sc_apikey: key, sc_site: site }).toString();

  try {
    const response = await fetch(url.href, {
      method: 'GET',
      headers,
      mode: 'same-origin',
      credentials: 'same-origin',
    });

    const statusCode = response?.status ?? 500;
    let layoutData = null;

    // Short-circuit redirects
    if (response.redirected) {
      logger.debug('vgdFqq', 'Redirect', { res: response });
      window.location.href = response.url;
      return { layoutData: null, statusCode };
    }

    if (response.ok || statusCode === 404) {
      try {
        layoutData = await response.json();
        return { layoutData: layoutData as unknown as LayoutServiceData, statusCode };
      } catch (error) {
        logger.error('9m782W', 'Error parsing Sitecore response', { error, req: { path, item, href: url.href } });
        throw error;
      }
    }

    logger.error('Ao9EMJ', 'Error in Sitecore response', { res: { statusCode }, req: { path, item, href: url.href } });
    throw new Error('Received error reponse from Sitecore');
  } catch (error) {
    logger.error('OhsQph', 'Sitecore client error', { error, req: { path, item, href: url.href } });
    throw error;
  }
};

export { client, fetchPlaceholder };
