import '@common/application-insights';

import { FC, ReactNode, useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import { Session } from 'next-auth';

import { ApplicationProvider, I18n } from '@common/application';
import { ErrorBoundary } from '@common/log';
import { SWRCacheProvider } from '@common/swr';
import { Label } from '@dc/client/types';
import DigitalCoreProvider from '@dc/provider';
import { Head, Link, useRouter } from '@dxp-next';
import { ENECO_SEGMENT_COOKIE } from '@dxp-next/constants';
import { LinkComponentProvider } from '@link';
import type { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { fetchPlaceholder } from '@sitecore/client/browser';
import type { ComponentsDefinition, LayoutServiceData } from '@sitecore/common';
import { PlaceholderProvider, SitecoreContext, VisitorIdentification } from '@sitecore/common';
import { LayoutServiceExperimentInfo } from '@sitecore/common/types';
import { SparkyProvider } from '@sparky/providers';
import { DataLayerEvent, TrackingProvider } from '@tracking';

import { ErrorPageContainer } from './Error';

export type SitecoreRouteProps = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  children: ReactNode;
  components: ComponentsDefinition;
  dataLayerEvents?: Array<DataLayerEvent>;
  i18n: I18n;
  idToken?: Session['idToken'] | null;
  label: Label;
  layoutData: LayoutServiceData;
  name: string;
  path: string;
  platformHint?: 'desktop' | 'mobile';
  user?: Session['user'] | null;
};

const SitecoreRouteContainer: FC<SitecoreRouteProps> = ({
  businessUnit,
  children,
  components,
  dataLayerEvents,
  i18n,
  idToken,
  label,
  layoutData,
  name,
  path,
  platformHint,
  user,
}) => {
  const route = layoutData?.sitecore?.route;
  const title = route?.fields?.browserTitle?.value || route?.fields?.pageTitle?.value || label;
  const metaDescription = route?.fields?.metaDescription?.value;
  const metaKeywords = route?.fields?.metaKeywords?.value;
  const [experimentInfo, setExperimentInfo] = useState<LayoutServiceExperimentInfo | undefined>(undefined);
  const sc_experimentInfo = layoutData?.sitecore?.context?.experimentInfo;

  const { asPath } = useRouter();

  function getIndexFollowStatus(): string {
    const { doNotFollow, doNotIndex } = route?.fields ?? {};
    const followStatus = doNotFollow?.value ? 'nofollow' : 'follow';
    const indexStatus = doNotIndex?.value ? 'noindex' : 'index';
    return `${indexStatus}, ${followStatus}`;
  }

  // Check if isPreferencePage is true and set the segment cookie
  useEffect(() => {
    if (route?.fields?.isPreferencePage?.value && layoutData?.sitecore?.context?.basePath) {
      const pathname = layoutData?.sitecore?.context?.basePath;
      const segmentKey = pathname.split('/').filter(Boolean).pop();
      Cookies.set(ENECO_SEGMENT_COOKIE, segmentKey || '');
    }
  }, [route?.fields?.isPreferencePage?.value, layoutData?.sitecore?.context?.basePath]);

  // Make the experimentInfo available on the global object for debugging,
  // and cache it so we always have the last defined version.
  useEffect(() => {
    if (sc_experimentInfo) {
      setExperimentInfo(sc_experimentInfo);
    }
    window.experimentInfo = sc_experimentInfo || experimentInfo;
  }, [sc_experimentInfo]);

  return (
    <ErrorBoundary
      fallback={
        <ErrorPageContainer
          businessUnit={businessUnit}
          components={components}
          i18n={i18n}
          label={label}
          layoutData={null}
          name={name}>
          {children}
        </ErrorPageContainer>
      }>
      <Head>
        <title>{title}</title>
        {<meta name="robots" content={getIndexFollowStatus()} />}
        {metaDescription && <meta name="description" content={String(metaDescription)} />}
        {metaKeywords && <meta name="keywords" content={String(metaKeywords)} />}
      </Head>
      <ApplicationProvider i18n={i18n} path={path} isEditMode={!!layoutData?.sitecore?.context?.pageEditing}>
        <TrackingProvider initialEvents={dataLayerEvents} scope={name}>
          <SparkyProvider locale={i18n.locale} platformHint={platformHint}>
            <SitecoreContext components={components} layoutData={layoutData}>
              <PlaceholderProvider fetchPlaceholder={fetchPlaceholder} idToken={idToken} currentRoutePath={asPath}>
                <VisitorIdentification defer />
                <SWRCacheProvider>
                  <DigitalCoreProvider
                    user={user}
                    idToken={idToken}
                    customerContext={layoutData.sitecore.context}
                    label={label}
                    businessUnit={businessUnit}>
                    <LinkComponentProvider component={Link}>{children}</LinkComponentProvider>
                  </DigitalCoreProvider>
                </SWRCacheProvider>
              </PlaceholderProvider>
            </SitecoreContext>
          </SparkyProvider>
        </TrackingProvider>
      </ApplicationProvider>
    </ErrorBoundary>
  );
};

export { SitecoreRouteContainer };
